import styled, { keyframes, css } from "styled-components";
import { Rem, BP } from "../../commons/Theme";
import {
  StyledSection as DefaultSection, StyledContainer, StyledLi, StyledTitle,
} from "../globalstyle";
import { motion } from "framer-motion";
import { Button } from "@3beehivetech/components";

const Li = motion(StyledLi);

export const StyledUlTitle = styled.div`
  padding-bottom:${Rem(5)};
`;

export const StyledColoredTitle = styled(StyledTitle)`
  ${(props) => 
    props.isValentine
    && css`
    .color-green-background, .color-yellow-background {
      padding: 1px 6px;
        @media (${BP.tablet}) {
          padding: 1px 20px;
        }
    }
  `}
`;

export const StyledButton = styled(Button)`
  ${(props) => 
    props.isValentine
    && css`
      padding:${Rem(16)} ${Rem(60)};
  `}
  ${(props) => 
    props.valentineCta
    && css`
      padding: 1rem;
      @media (${BP.tablet}) {
        padding:${Rem(16)} ${Rem(60)};
      }
  `}
  
`;

export const StyledLiMotion = styled(Li)``;

export const StyledUl = styled(motion.ul)`
padding-top:${Rem(20)};
list-style-type: none;
`;

const StyledSection = styled(DefaultSection)`
  /* padding: ${Rem(40)} 0; */
  padding: ${padded => padded === false ? "2.5rem 0" : "0"};
  margin-top: ${padded => padded === false ? "1.125rem 0" : "0"};
  /* margin-top: ${Rem(18)}; */
  @media (${BP.tablet}) {
    padding-bottom: 0;
    padding: ${Rem(0)} 0;
  }

  & > ${StyledContainer} {
    display: flex;
    flex-direction: ${props => props.topImage === true ? "column-reverse" : "column"};
    @media (${BP.tablet}) {
      flex-direction: ${props => props.rightImage === true ? "row" : "row-reverse"};
    }
  }

  & + ${DefaultSection} {    
    padding-top: ${Rem(20)};
    z-index: 0;
  }
`;

StyledSection.Content = styled(DefaultSection.Content)`
  align-self: center;

  & p {
    margin-bottom: ${Rem(20)};
  }

  & ul {
    margin-left: 10px;
  }

  & li {
    list-style: disc;
   }
`;

StyledSection.Visual = styled(DefaultSection.Visual)`
  width: 100%;
 
  margin-top: 0 !important;
  margin-bottom: ${Rem(0)};

  @media (${BP.tablet}) {
    width: 45%;
    
    margin-bottom: 0;
    margin-left: 0 !important;
  }

  svg {
    width: 100%;
    position: absolute;
    bottom: ${Rem(-180)};
    left: ${Rem(-20)};;
  }
  
  img {
    width: 100%;

    @media (${BP.tablet}) {
     
      bottom: ${Rem(0)};
      left: 0;
    }
  }
`;

const beeMove = keyframes`
  0% {
    offset-distance: 0%;
    opacity: 0;
  }
  50% {
    offset-distance: 50%;
    opacity: 1;
  }
  100% {
    offset-distance: 100%;
    opacity: 0;
  }
`;

export const StyledBeeOne = styled.g`
  offset-path: path('M1112.6 295.2s32.3-175.4-135.1-135.5-136.3 281.6-128.6 353.6 57.6 296-56.5 393.5-251.1 48.7-242.5-95.4S681.5 579.8 785.5 547s279.4-84.6 327.1-251.8z');
  animation: ${beeMove} 15s infinite normal linear;
  transform: rotate(60deg);
`;

export const StyledBeeTwo = styled.g`
  offset-path: path('M1329.9 972.4s-121.3-10.3-179.6-19.3-258.1 9-283.1 144.7 69.4 126.9 92.1 120 102.8-27.5 171.6-169.7 129.6-214.9 184.9-233.1c55.2-18.2 118.8-9.2 137.5 36.4 18.3 44.8-19.5 116.5-123.4 121z');
  animation: ${beeMove} 15s infinite normal linear;
  transform: rotate(190deg);
`;

export { StyledSection };
